<template>
  <div class="tooltip-content">
    <p v-if="betRateMessage">{{ betRateMessage }}</p>
    <p>{{ betTypeMessage }}</p>
  </div>
</template>

<script setup lang="ts">
import type { Freebet } from '@st/bonuses/types'

const props = defineProps<{
  freebet: Freebet
}>()

const { t, locale } = useI18n()

const { format } = useNumberFormatter()

const betRateMessage = computed(() => {
  const { minBetRate, maxBetRate } = props.freebet.freebetSettings

  if (!minBetRate && !maxBetRate) return ''

  if (minBetRate && !maxBetRate)
    return t('coupon.freebet.betRateMin', { min: format(minBetRate) })

  if (maxBetRate && !minBetRate)
    return t('coupon.freebet.betRateMax', { max: format(maxBetRate) })

  return t('coupon.freebet.betRateRange', {
    min: format(minBetRate as string),
    max: format(maxBetRate as string),
  })
})

const betTypeMessage = computed(() => {
  const betTypeMap = {
    ordinary: t('coupon.freebet.forOrdinaries'),
    express: t('coupon.freebet.forExpresses'),
  }

  const listFormatter = new Intl.ListFormat(locale.value, {
    style: 'long',
    type: 'conjunction',
  })

  const localizedBetTypes = props.freebet.freebetSettings.allowedBetTypes.map(
    (type) => betTypeMap[type],
  )

  const betTypesList = listFormatter.format(localizedBetTypes)

  return t('coupon.freebet.forBetTypes', { betTypesList })
})
</script>

<style scoped>
.tooltip-content {
  max-width: 200px;
  padding: var(--spacing-100, 8px) var(--spacing-150, 12px);
  font: var(--desktop-text-xs-medium);

  > p {
    margin: 0;
    padding: 0;
  }
}
</style>
