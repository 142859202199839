import type { Ref } from 'vue'
import type { Freebet } from '@st/bonuses/types'
import type { ExtendedCouponOutcome } from '../stores/useCouponStore'
import { type CreateBetResult, useCreateBet } from './useCreateBet'

type MaybeRef<T> = T | Ref

interface UseExpressBetParams {
  outcomes: MaybeRef<ExtendedCouponOutcome[]>
  amount: MaybeRef<string>
  freebet: MaybeRef<Freebet | undefined>
  accountId: MaybeRef<number>
}

interface UseExpressBetReturn {
  createExpressBet: () => Promise<CreateBetResult>
}

export function useCreateExpressBet(
  params: UseExpressBetParams,
): UseExpressBetReturn {
  const { createBet } = useCreateBet()

  function createExpressBet() {
    if (unref(params.freebet)) {
      return createBet({
        betType: 'express',
        outcomes: unref(params.outcomes),
        freebet: unref(params.freebet),
      })
    }
    return createBet({
      betType: 'express',
      outcomes: unref(params.outcomes),
      amount: unref(params.amount),
      accountId: unref(params.accountId),
    })
  }

  return {
    createExpressBet,
  }
}
