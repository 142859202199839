import type { Ref } from 'vue'
import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import Decimal from '@st/decimal'
import { useConverterStore } from '@st/payments/stores/useConverterStore'
import { useCurrenciesStore } from '@st/payments/stores/useCurrenciesStore'
import { useUserStore } from '@st/user/stores/useUserStore'
import type { ExtendedCouponOutcome } from '../stores/useCouponStore'

type MaybeRef<T> = T | Ref<T>

/**
 * Минимальная и максимальная сумма ставки
 */
export interface Limit {
  min: string
  max: string
}

export interface UseLimitsParams {
  outcomes: MaybeRef<ExtendedCouponOutcome[]>
  currencyId: MaybeRef<number>
}

export interface UseLimitsReturn {
  expressLimit: Ref<Limit>
  // Мапа [outcome.uniqId] - лимит для ординара на этот ауткам
  ordinaryLimitsByOutcome: Ref<Record<string, Limit>>
}

// TODO конвертирование валюты
export function useLimits(params: UseLimitsParams): UseLimitsReturn {
  const outcomes = computed(() => toValue(params.outcomes))
  const currencyId = computed(() => toValue(params.currencyId))

  const { settings } = storeToRefs(useSettingsStore())
  const { convert } = useConverterStore()
  const { appCurrency } = storeToRefs(useCurrenciesStore())
  const minBetAmount = computed(
    () =>
      settings.value?.currencySettings?.[currencyId.value]?.betMinAmount ?? '0',
  )

  const customMenu = useCustomMenu()

  function transformOutcomeToPostLimitFormat(outcome: ExtendedCouponOutcome) {
    return {
      sbOutcomeId: outcome.outcomeId,
      sbSportEventId: outcome.eventId,
      rate: outcome.outcome.odds.toString(),
      sbMarketSpecifiers: outcome.marketSpecifiers,
      sbMarketId: outcome.marketId,
      sbCategoryId: outcome.event.categoryId,
      sbSportId: outcome.event.sportId,
      scheduled: new Date(outcome.event.scheduled).toISOString(),
      sbMarketWeight: outcome.market.weight,
      sbSportEventStatus: outcome.event.status,
      sbSportEventWeight: outcome.event.weight,
      sbSportWeight:
        customMenu.value.findById('sport', outcome.event.sportId)?.weight ?? 1,
      sbTournamentId: outcome.event.tournamentId,
    }
  }

  const { data: limits, execute: fetchLimits } = useStFetch('/limit', {
    method: 'post',
    body: computed(() => ({
      outcomes: outcomes.value.map(transformOutcomeToPostLimitFormat),
    })),
    immediate: false,
    watch: false,
  })
  const { user } = storeToRefs(useUserStore())
  watchEffect(() => {
    if (user.value && outcomes.value.length) {
      fetchLimits()
    }
  })

  /**
   * По мистическим причинам максимальная сумма ставки считается по формуле
   * maxBetAmount = maxBetPayoff / (betRate - 1)
   */
  function calculateMaxBetAmount(
    maxBetPayoff: string | number,
    betRate: string | number,
  ) {
    const correctedBetRate = new Decimal(betRate).minus(1)

    return new Decimal(maxBetPayoff)
      .dividedBy(correctedBetRate)
      .toDecimalPlaces(8)
      .toString()
  }

  const expressRate = computed(() =>
    outcomes.value
      .reduce<Decimal>(
        (acc, outcome) => new Decimal(outcome.outcome.odds).times(acc),
        new Decimal(1),
      )
      .toString(),
  )

  const expressMaxBetAmount = computed(() => {
    const limit = limits.value?.express
    if (!limit || Array.isArray(limit)) return undefined

    const limitInBetCurrency = convert(limit.betPayoffLimit || '0', {
      from: appCurrency.value.code,
      to: currencyId.value,
    })

    if (!limitInBetCurrency) return undefined

    return calculateMaxBetAmount(limitInBetCurrency, expressRate.value)
  })

  const expressLimit = computed<Limit>(() => ({
    min: minBetAmount.value,
    max: expressMaxBetAmount.value ?? '0',
  }))

  function findOrdinaryMaxBetAmount(outcome: ExtendedCouponOutcome) {
    const ordinaryLimits = limits.value?.ordinary
    if (!Array.isArray(ordinaryLimits)) return undefined

    const limit = ordinaryLimits.find(
      ({ sbSportEventId }) => outcome.eventId === sbSportEventId,
    )
    if (!limit) return undefined

    const limitInBetCurrency = convert(limit.betPayoffLimit, {
      from: appCurrency.value.code,
      to: currencyId.value,
    })

    if (!limitInBetCurrency) return undefined

    return calculateMaxBetAmount(limitInBetCurrency, outcome.outcome.odds)
  }

  const ordinaryLimitsByOutcome = computed(() =>
    outcomes.value.reduce<Record<string, Limit>>((acc, outcome) => {
      const maxBetAmount = findOrdinaryMaxBetAmount(outcome)

      acc[outcome.uniqId] = {
        min: minBetAmount.value,
        max: maxBetAmount ?? '0',
      }
      return acc
    }, {}),
  )

  return {
    expressLimit,
    ordinaryLimitsByOutcome,
  }
}
