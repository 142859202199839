<template>
  <article class="not-enought-error">
    <StIcon class="error-icon" name="wallet-solid" :size="20" />
    <p>{{ t('coupon.errors.notEnoughBalanceTextShort') }}</p>

    <StButton type="secondary" @click.stop="handleDeposit">
      {{ t('coupon.makeDeposit') }}
    </StButton>
  </article>
</template>

<script setup lang="ts">
const { t } = useI18n()

const emit = defineEmits<{
  (e: 'onClose'): void
}>()

const router = useRouter()
function handleDeposit() {
  emit('onClose')
  router.replace({ query: { modal: 'payments', tab: 'deposit' } })
}
</script>

<style scoped>
.not-enought-error {
  position: relative;

  display: flex;
  gap: var(--spacing-150);
  align-items: center;
  justify-content: space-between;

  padding: var(--spacing-100) var(--spacing-100) var(--spacing-100)
    var(--spacing-150);

  font: var(--mobile-text-content-regular);
  color: var(--text-primary);

  background: var(--background-secondary);
  border-radius: var(--border-radius-150);

  p {
    margin: 0;
    text-align: left;
  }
}

.error-icon {
  min-width: 20px;
}
</style>
