<template>
  <OrdinarySummaryForMutipleCurrencies
    v-if="hasMultipleCurrencies"
    :ordinaries="ordinariesWithPositiveAmount"
  />
  <OrdinarySummaryForSingleCurrency
    v-else
    :ordinaries="ordinariesWithPositiveAmount"
  />
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
import type { Ordinary } from '../../../composables/useCreateOrdinaryBet'
import OrdinarySummaryForSingleCurrency from './OrdinarySummaryForSingleCurrency.vue'
import OrdinarySummaryForMutipleCurrencies from './OrdinarySummaryForMutipleCurrencies.vue'

const props = defineProps<{
  ordinaries: Ordinary[]
}>()

const ordinariesWithPositiveAmount = computed(() =>
  props.ordinaries.filter((ordinary) =>
    new Decimal(ordinary.amount || 0).greaterThan(0),
  ),
)

const hasMultipleCurrencies = computed(() => {
  if (!ordinariesWithPositiveAmount.value.length) return false

  const firstCurrencyId = ordinariesWithPositiveAmount.value[0].currencyId
  return ordinariesWithPositiveAmount.value.some(
    (ordinary) => ordinary.currencyId !== firstCurrencyId,
  )
})
</script>
