<template>
  <StTooltip
    :disabled="!isShownTooltip"
    disable-teleport
    placement="top"
    :trigger="isMobile ? 'click' : 'hover'"
  >
    <template #activator>
      <button
        class="freebet"
        type="button"
        :class="{ disabled: isDisabledButton }"
        @click="emit('select')"
      >
        <StIcon v-if="!isAllowed" name="lock-solid" :size="14" />
        <StIcon v-if="selected" name="check" class="check-icon" :size="16" />
        {{ freebet.amount }}
        <StIcon :name="freebet.currencyIcon" :size="16" />
      </button>
    </template>
    <FreebetSettingsTooltip :freebet="freebet" />
  </StTooltip>
</template>

<script setup lang="ts">
import type { Freebet } from '@st/bonuses/types'
import Decimal from '@st/decimal'
import FreebetSettingsTooltip from './FreebetSettingsTooltip.vue'

const { isMobile } = usePlatform()

const props = defineProps<{
  freebet: Freebet
  betRate: string
  betType: 'express' | 'ordinary'
  selected?: boolean
  alreadySelectedFreebets?: Freebet[]
}>()

const emit = defineEmits<{
  (e: 'select'): void
}>()

const isAllowed = computed(() => {
  const { allowedBetTypes, maxBetRate, minBetRate } =
    props.freebet.freebetSettings
  const { betType, betRate } = props

  const isAllowedBetType = allowedBetTypes.includes(betType)
  const isAboveMinBetRate =
    !minBetRate || new Decimal(betRate).greaterThanOrEqualTo(minBetRate)
  const isBelowMaxBetRate =
    !maxBetRate || new Decimal(betRate).lessThanOrEqualTo(maxBetRate)

  return isAllowedBetType && isAboveMinBetRate && isBelowMaxBetRate
})

const isAlreadySelected = computed(
  () =>
    !props.selected &&
    props.alreadySelectedFreebets &&
    props.alreadySelectedFreebets.some(({ id }) => props.freebet.id === id),
)

const isShownTooltip = computed(() => !isAllowed.value)
const isDisabledButton = computed(
  () => isAlreadySelected.value || !isAllowed.value,
)
</script>

<style scoped>
.check-icon {
  color: var(--icon-success);
}

.freebet {
  all: unset;

  cursor: pointer;
  user-select: none;

  display: flex;
  gap: var(--spacing-050);
  align-items: center;
  justify-content: center;

  box-sizing: border-box;
  padding: var(--spacing-075) var(--spacing-125);

  font: var(--desktop-text-sm-semibold);

  background: var(--button-gray-default);
  border-radius: var(--border-radius-100);

  &.disabled {
    pointer-events: none;
    opacity: 0.4;
  }
}
</style>
