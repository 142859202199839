import Decimal from '@st/decimal'
import type { Limit } from './useLimits'

export interface ValidateBetAmountParams {
  amount: string
  limit: Limit
  balance: string
}

export type ErrorType = 'notEnoughBalance' | null | undefined

export interface ValidateBetAmountReturn {
  isValid: boolean
  error?: string
  errorType?: ErrorType
}

export function useValidateBetAmount() {
  const { t } = useI18n()
  const { format } = useCryptoFormatter()

  return (params: ValidateBetAmountParams): ValidateBetAmountReturn => {
    const decimalAmount = new Decimal(params.amount || 0)

    if (decimalAmount.greaterThan(params.balance)) {
      return {
        error: t('coupon.errors.notEnoughBalance'),
        isValid: false,
        errorType: 'notEnoughBalance',
      }
    }
    if (decimalAmount.greaterThan(params.limit.max)) {
      return {
        error: t('coupon.errors.maxBetAmount', {
          amount: format(params.limit.max),
        }),
        isValid: false,
      }
    }
    if (decimalAmount.lessThan(params.limit.min)) {
      return {
        error: t('coupon.errors.minBetAmount', {
          amount: format(params.limit.min),
        }),
        isValid: false,
      }
    }

    return {
      isValid: true,
    }
  }
}
