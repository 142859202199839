import type { Freebet } from '@st/bonuses/types'
import type { ExtendedCouponOutcome } from '../stores/useCouponStore'
import type { BetStatus } from '../types'

export type CreateBetError =
  | 'SOCKET_REJECTED'
  | 'MARKET_NOT_ACTIVE'
  | 'BET_RATE_CHANGED'

export type CreateBetParams =
  | {
      betType: 'express' | 'ordinary'
      outcomes: ExtendedCouponOutcome[]
      amount: string
      accountId: number
      freebet?: never
    }
  | {
      betType: 'express' | 'ordinary'
      outcomes: ExtendedCouponOutcome[]
      freebet: Freebet
      amount?: never
      accountId?: never
    }

export type CreateBetResult =
  | {
      isAccepted: true
      betId: number
    }
  | {
      isAccepted: false
      error: CreateBetError
    }

export function useCreateBet() {
  const io = useSocket()
  const stFetch = useRawStFetch()

  function waitForBetStatusSocket(betId: number) {
    return new Promise<boolean>((resolve) => {
      const createBetListener = (payload: {
        betId: number
        betStatus: BetStatus
      }) => {
        if (payload.betId !== betId) return

        if (payload.betStatus === 'accepted') {
          // Звуки рекурсивного безумия усиливаются. Вы получаете 6 ед. психического урона
          io.off('betStatus', createBetListener)
          resolve(true)
        } else if (payload.betStatus === 'unaccepted') {
          io.off('betStatus', createBetListener)
          resolve(false)
        }
      }

      io.on('betStatus', createBetListener)
    })
  }

  async function createBet({
    outcomes,
    amount,
    accountId,
    betType,
    freebet,
  }: CreateBetParams): Promise<CreateBetResult> {
    const formattedOutcomes = outcomes.map((outcome) => ({
      sbOutcomeId: outcome.outcomeId,
      sbMarketId: outcome.marketId,
      rate: outcome.outcome.odds.toString(),
      sbMarketSpecifiers: outcome.marketSpecifiers,
      sbSportEventId: outcome.eventId,
    }))

    const requestBody = freebet
      ? {
          betType,
          amount: freebet.amount,
          freebetId: freebet.id,
          accountId: freebet.accountId,
          outcomes: formattedOutcomes,
        }
      : {
          betType,
          amount,
          accountId,
          outcomes: formattedOutcomes,
        }

    let response: {
      betId: number
      delay?: number
    }

    try {
      response = await stFetch('/bet', {
        method: 'post',
        body: requestBody,
      })
    } catch (error: any) {
      const code = error?.data?.error as CreateBetError
      return {
        isAccepted: false,
        error: code,
      }
    }

    if (!response.delay) {
      return {
        isAccepted: true,
        betId: response.betId,
      }
    }

    const isAcceptedBySocket = await waitForBetStatusSocket(response.betId)
    return isAcceptedBySocket
      ? {
          isAccepted: true,
          betId: response.betId,
        }
      : {
          isAccepted: false,
          error: 'SOCKET_REJECTED',
        }
  }

  return { createBet }
}
