import type { ErrorType } from './useValidateBetAmount'

export function useNotEnoughtError() {
  const betErrorType = ref<ErrorType>(null)
  const isNegativeBallanceError = computed(
    () => betErrorType.value === 'notEnoughBalance',
  )
  function closeNegativeBallanceError() {
    betErrorType.value = null
  }

  return {
    isNegativeBallanceError,
    betErrorType,
    closeNegativeBallanceError,
  }
}
