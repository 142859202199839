<template>
  <div class="summary">
    <div class="summary-row">
      <span class="summary-label">
        {{ t('coupon.ordinaryTotalBet') }}
      </span>
      <span class="summary-amount" data-t="summary-bet-yst4">
        {{ format(totalAmount) }}
        <StIcon :size="16" :name="currencyIcon" />
      </span>
    </div>
    <div class="summary-row">
      <span class="summary-label">
        {{ t('coupon.ordinaryTotalPayoff') }}
      </span>
      <span class="summary-amount" data-t="summary-amount-3nfk">
        {{ format(totalPayoff) }}
        <StIcon :size="16" :name="currencyIcon" />
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
import { useAccountsStore } from '@st/payments/stores/useAccountsStore'
import type { Ordinary } from '../../../composables/useCreateOrdinaryBet'

const { t } = useI18n()
const { format } = useCryptoFormatter()

const props = defineProps<{
  ordinaries: Ordinary[]
}>()

const { activeAccount } = storeToRefs(useAccountsStore())

const currencyIcon = computed(
  () => props.ordinaries?.[0]?.currencyIcon ?? activeAccount.value?.icon,
)

const totalAmount = computed(() => {
  const decimalAmount = props.ordinaries.reduce(
    (sum, ordinary) => sum.plus(ordinary.amount || 0),
    new Decimal(0),
  )

  return decimalAmount.toString()
})

const totalPayoff = computed(() => {
  const decimalPayoff = props.ordinaries.reduce((sum, ordinary) => {
    const payoff = new Decimal(ordinary.amount || 0).times(
      ordinary.outcome.outcome.odds,
    )
    return sum.plus(payoff)
  }, new Decimal(0))

  return decimalPayoff.toString()
})
</script>

<style scoped>
.summary {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
  padding: var(--spacing-125) var(--spacing-100);
}

.summary-row {
  display: flex;
  justify-content: space-between;
}

.summary-label {
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.summary-amount {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
  font: var(--desktop-text-sm-semibold);
}
</style>
