<template>
  <form
    class="ordinary-form"
    :class="{ mobile }"
    @submit.prevent="handleSubmit"
  >
    <CouponScrollable class="outcomes-list">
      <OrdinaryFormItem
        v-for="outcome in outcomes"
        :key="outcome.uniqId"
        v-model:amount="amountsByOutcome[outcome.uniqId]"
        v-model:freebet="freebetsByOutcome[outcome.uniqId]"
        :currency-icon="currencyIcon"
        :outcome="outcome"
        :max-amount="getMaxAmount(outcome)"
        :error="amountErrors[outcome.uniqId]"
        :show-max-button="isAuthenticated"
        :mobile="mobile"
        class="ordinary"
        :already-selected-freebets="usedFreebets"
      />
    </CouponScrollable>
    <div class="ordinary-form-bottom">
      <OrdinarySummary :ordinaries="ordinaries" />
      <StTransitionExpand>
        <p v-if="betError" class="bet-error">
          {{ betError }}
        </p>
      </StTransitionExpand>
      <StTransitionExpand>
        <MNotEnoughtError
          v-if="isNegativeBallanceError && mobile"
          class="balance-error"
          @on-close="handleCloseNegativeError"
        />
      </StTransitionExpand>
      <StButton
        block
        type="primary"
        submit
        class="submit-button"
        :label="buttonLabel"
        :loading="isLoading"
        data-t="form-submit-button-4lyb"
      />
    </div>

    <StTransitionExpand>
      <NotEnoughtError
        v-if="isNegativeBallanceError && !mobile"
        @on-close="closeNegativeBallanceError"
      />
    </StTransitionExpand>
  </form>
</template>

<script setup lang="ts">
import { useAccountsStore } from '@st/payments/stores/useAccountsStore'
import Decimal from '@st/decimal'
import { useUserStore } from '@st/user/stores/useUserStore'
import type { Freebet } from '@st/bonuses/types'
import {
  type ExtendedCouponOutcome,
  useCouponStore,
} from '../../stores/useCouponStore'
import {
  useCreateOrdinaryBet,
  type Ordinary,
} from '../../composables/useCreateOrdinaryBet'
import OrdinarySummary from './parts/OrdinarySummary.vue'
import OrdinaryFormItem from './parts/OrdinaryFormItem.vue'
import { useLimits } from '../../composables/useLimits'
import type {
  CreateBetResult,
  CreateBetError,
} from '../../composables/useCreateBet'
import { useLocalizeBetError } from '../../composables/useLocalizeBetError'
import { useValidateBetAmount } from '../../composables/useValidateBetAmount'
import { useNotEnoughtError } from '../../composables/useNotEnoughtError'
import NotEnoughtError from '../NotEnoughtError/NotEnoughtError.vue'
import MNotEnoughtError from '../NotEnoughtError/MNotEnoughtError.vue'

defineProps<{
  mobile?: boolean
}>()

const { t } = useI18n()

const { isAuthenticated } = storeToRefs(useUserStore())
const couponStore = useCouponStore()
const { outcomes, isLoading } = storeToRefs(useCouponStore())
const accountsStore = useAccountsStore()
const { activeAccount } = storeToRefs(accountsStore)
const localizeBetError = useLocalizeBetError()

const amountsByOutcome = ref<Record<string, string>>({})
const freebetsByOutcome = ref<Record<string, Freebet | undefined>>({})

const usedFreebets = computed(
  () => Object.values(freebetsByOutcome.value).filter(Boolean) as Freebet[],
)

const ordinaries = computed<Ordinary[]>(() =>
  outcomes.value.map((outcome) => {
    const freebet = freebetsByOutcome.value[outcome.uniqId]
    const amount =
      freebet?.amount ?? amountsByOutcome.value[outcome.uniqId] ?? '0'
    const icon =
      freebet?.currencyIcon ?? activeAccount.value?.icon ?? 'c-crypto-icon-usdt'
    const currencyId =
      freebet?.currencyId ?? activeAccount.value?.currencyId ?? 1

    return {
      outcome,
      amount,
      freebet,
      currencyIcon: icon,
      currencyId,
    }
  }),
)

const { ordinaryLimitsByOutcome } = useLimits({
  outcomes,
  currencyId: computed(() => activeAccount.value?.currencyId ?? 1),
})

const currencyIcon = computed(() =>
  isAuthenticated.value ? activeAccount.value?.icon : 'c-crypto-icon-usdt',
)

function getMaxAmount(outcome: ExtendedCouponOutcome): string {
  return Decimal.min(
    activeAccount.value?.balance ?? 0,
    ordinaryLimitsByOutcome.value[outcome.uniqId].max,
  ).toString()
}

const buttonLabel = ref(t('coupon.makeBet'))

const { createOrdinaryBet } = useCreateOrdinaryBet()

const toast = useToast()

const betError = ref('')
const { isNegativeBallanceError, betErrorType, closeNegativeBallanceError } =
  useNotEnoughtError()

const validateBetAmount = useValidateBetAmount()
const amountErrors = ref<Record<string, string>>({})
const router = useRouter()

function handleResults(resultsByOutcome: Record<string, CreateBetResult>) {
  let acceptedCount = 0
  const totalCount = outcomes.value.length
  let errorToShow: CreateBetError | null = null

  Object.entries(resultsByOutcome).forEach(([outcomeId, result]) => {
    if (result.isAccepted) {
      acceptedCount += 1
      delete freebetsByOutcome.value[outcomeId]
      delete amountsByOutcome.value[outcomeId]
      delete amountErrors.value[outcomeId]
      couponStore.removeOutcome(outcomeId)
    } else {
      errorToShow ??= result.error
    }
  })

  if (errorToShow) betError.value = localizeBetError(errorToShow)

  if (acceptedCount === 1 && totalCount === 1) {
    toast.open({
      label: t('coupon.betIsAccepted'),
      type: 'positive',
    })
  } else if (acceptedCount > 0) {
    toast.open({
      label: t('coupon.someBetsAreAccepted', {
        accepted: acceptedCount,
        total: totalCount,
      }),
      type: 'positive',
    })
  } else {
    toast.open({
      label: t('coupon.betIsNotAccepted'),
      type: 'negative',
    })
  }
}

function validate() {
  const validOrdinaries: Ordinary[] = []
  const errors: Record<string, string> = {}
  let hasInactiveOutcomes = false

  ordinaries.value.forEach((ordinary) => {
    const { outcome, amount } = ordinary

    if (!outcome.isActive) {
      hasInactiveOutcomes = true
      return
    }

    if (ordinary.freebet) {
      validOrdinaries.push(ordinary)
      return
    }

    const { isValid, error, errorType } = validateBetAmount({
      amount,
      limit: ordinaryLimitsByOutcome.value[outcome.uniqId],
      balance: activeAccount.value.balance,
    })

    if (!isValid) {
      errors[outcome.uniqId] = error ?? ''
      betErrorType.value = errorType
    } else {
      validOrdinaries.push(ordinary)
    }
  })

  const showValidationErrors = () => {
    amountErrors.value = errors
    if (hasInactiveOutcomes) {
      betError.value = t('coupon.errors.marketNotActive')
    }
  }

  return { validOrdinaries, showValidationErrors }
}

async function handleSubmit() {
  if (!isAuthenticated.value) {
    router.replace({ query: { modal: 'login' } })
    return
  }

  const { validOrdinaries, showValidationErrors } = validate()

  if (validOrdinaries.length === 0) {
    showValidationErrors()
    return
  }

  isLoading.value = true
  const resultsByOutcome = await createOrdinaryBet({
    ordinaries: validOrdinaries,
    accountId: activeAccount.value.id,
  })
  isLoading.value = false

  showValidationErrors()
  handleResults(resultsByOutcome)
}

const emits = defineEmits<{
  (e: 'close'): void
}>()
async function handleCloseNegativeError() {
  closeNegativeBallanceError()
  emits('close')
}
</script>

<style scoped>
.ordinary-form-bottom {
  padding: 0 var(--spacing-150) var(--spacing-150);
}

.outcomes-list {
  flex-shrink: 1;
  min-height: 0;
  padding: 0 var(--spacing-150);
}

.ordinary:not(:first-child) {
  margin-top: var(--spacing-075);
}

.bet-error {
  margin: 0 0 var(--spacing-100);
  padding: 0 var(--spacing-100);
  font: var(--desktop-text-xs-medium);
  color: var(--system-error);
}

.submit-button.loading {
  position: relative;
  z-index: 2;
  box-shadow: 0 0 0 100vmax rgba(0 0 0 / 60%);
}

.ordinary-form {
  display: flex;
  flex-direction: column;

  &.mobile {
    .outcomes-list {
      padding-inline: var(--spacing-200);
    }

    .ordinary-form-bottom {
      padding: 0 var(--spacing-200) var(--spacing-150);
    }
  }
}

.balance-error {
  margin-bottom: var(--spacing-150);
}
</style>
