<template>
  <div class="not-enought-error-container" @click.stop="emit('onClose')">
    <article class="not-enought-error">
      <header class="error-header">
        <StIcon name="wallet-solid" :size="36" />
        <p>{{ t('coupon.errors.notEnoughBalanceText') }}</p>
        <button
          class="close-button"
          type="button"
          @click.stop="emit('onClose')"
        >
          <StIcon name="cross-large" :size="16" />
        </button>
      </header>
      <StButton type="secondary" @click.stop="handleDeposit">
        {{ t('coupon.makeDeposit') }}
      </StButton>
    </article>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()

const emit = defineEmits<{
  (e: 'onClose'): void
}>()

const router = useRouter()
function handleDeposit() {
  emit('onClose')
  router.replace({ query: { modal: 'payments', tab: 'deposit' } })
}
</script>

<style scoped>
.not-enought-error-container {
  position: absolute;
  z-index: 10;
  inset: 0;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &::after {
    content: '';

    position: absolute;
    z-index: -1;
    inset: 0;

    opacity: 0.76;
    background: #14141a;
  }
}

.not-enought-error {
  position: relative;

  display: flex;
  flex-direction: column;
  gap: var(--spacing-300);

  padding: var(--spacing-300) var(--spacing-200);

  background: var(--background-secondary);
  border-radius: var(--border-radius-200) var(--border-radius-200) 0 0;
}

.error-header {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-200);
  align-items: center;
  justify-content: center;

  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);

  p {
    margin: 0;
    text-align: center;
  }
}

.close-button {
  all: unset;

  cursor: pointer;

  position: absolute;
  top: var(--spacing-200);
  right: var(--spacing-200);

  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-100);

  color: var(--text-tertiary);
}
</style>
