<template>
  <div class="summary">
    <div class="summary-row">
      <span class="summary-label">
        {{ t('coupon.ordinaryTotalBet') }}
      </span>
      <span class="summary-amount">
        ~{{ formatFiat(summary.totalAmount) }}
        <StTooltip disable-teleport placement="top-end">
          <template #activator>
            <StIcon
              name="circle-info-solid"
              :size="16"
              class="tooltip-button"
            />
          </template>
          <div class="tooltip-content">
            <div
              v-for="(amount, currencyId) in summary.amountByCurrency"
              :key="currencyId"
              class="tooltip-row"
            >
              {{ formatCrypto(amount) }}
              <StIcon :name="getCurrencyIcon(+currencyId)" :size="16" />
            </div>
          </div>
        </StTooltip>
      </span>
    </div>
    <div class="summary-row">
      <span class="summary-label">
        {{ t('coupon.ordinaryTotalPayoff') }}
      </span>
      <span class="summary-amount">
        ~{{ formatFiat(summary.totalPayoff) }}
        <StTooltip disable-teleport placement="top-end">
          <template #activator>
            <StIcon
              name="circle-info-solid"
              :size="16"
              class="tooltip-button"
            />
          </template>
          <div class="tooltip-content">
            <div
              v-for="(payoff, currencyId) in summary.payoffByCurrency"
              :key="currencyId"
              class="tooltip-row"
            >
              {{ formatCrypto(payoff) }}
              <StIcon :name="getCurrencyIcon(+currencyId)" :size="16" />
            </div>
          </div>
        </StTooltip>
      </span>
    </div>
  </div>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
import { useConverterStore } from '@st/payments/stores/useConverterStore'
import { useCurrenciesStore } from '@st/payments/stores/useCurrenciesStore'
import type { Ordinary } from '../../../composables/useCreateOrdinaryBet'

const props = defineProps<{
  ordinaries: Ordinary[]
}>()

const { convert } = useConverterStore()
const { appCurrency, currencies } = storeToRefs(useCurrenciesStore())

function getCurrencyIcon(currencyId: number) {
  return currencies.value[currencyId].icon
}

const { t } = useI18n()
const { format: formatFiat } = useCurrencyFormatter({
  currency: appCurrency.value.code,
})
const { format: formatCrypto } = useCryptoFormatter()

const summary = computed(() => {
  const appCurrencyId = appCurrency.value.id

  let totalAmount = new Decimal(0)
  let totalPayoff = new Decimal(0)

  const amountByCurrency: Record<string, string> = {}
  const payoffByCurrency: Record<string, string> = {}

  props.ordinaries.forEach((ordinary) => {
    const { amount, currencyId, outcome } = ordinary

    if (new Decimal(amount).isZero()) return

    const payoff = new Decimal(amount).mul(outcome.outcome.odds).toString()

    amountByCurrency[currencyId] ??= '0'
    payoffByCurrency[currencyId] ??= '0'

    amountByCurrency[currencyId] = new Decimal(amountByCurrency[currencyId])
      .add(amount)
      .toString()

    payoffByCurrency[currencyId] = new Decimal(payoffByCurrency[currencyId])
      .add(payoff)
      .toString()

    const amountInAppCurrency = convert(amount, {
      from: currencyId,
      to: appCurrencyId,
    })
    const payoffInAppCurrency = convert(payoff, {
      from: currencyId,
      to: appCurrencyId,
    })

    totalAmount = totalAmount.add(amountInAppCurrency)
    totalPayoff = totalPayoff.add(payoffInAppCurrency)
  })

  return {
    totalAmount: totalAmount.toString(),
    totalPayoff: totalPayoff.toString(),
    amountByCurrency,
    payoffByCurrency,
  }
})
</script>

<style scoped>
.summary {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-050);
  padding: var(--spacing-125) var(--spacing-100);
}

.summary-row {
  display: flex;
  justify-content: space-between;
}

.summary-label {
  font: var(--desktop-text-sm-medium);
  color: var(--text-tertiary);
}

.summary-amount {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
  font: var(--desktop-text-sm-semibold);
}

.tooltip-button {
  color: var(--icon-tertiary);
}

.tooltip-content {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-025);
  padding: var(--spacing-100) var(--spacing-150);
}

.tooltip-row {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;
  justify-content: flex-end;

  font: var(--desktop-text-sm-semibold);
}
</style>
