<template>
  <form class="express-form" :class="{ mobile }" @submit.prevent="handleSubmit">
    <CouponScrollable class="outcomes-wrapper">
      <ExpressOutcomesList :outcomes="outcomes" :mobile="mobile" />
    </CouponScrollable>
    <div class="express-bottom">
      <BetAmountInput
        v-model="amount"
        :max-amount="maxAmount"
        class="amount-input"
        type="express"
        :freebet="freebet"
        :currency-icon="currencyIcon"
        :error="!!amountError"
        :error-message="amountError"
        :show-max-button="isAuthenticated"
        data-t="amount-input-express-k0ux"
      />
      <FreebetsSelect
        v-if="notUsedFreebets.length"
        v-model="freebet"
        :bet-rate="totalRate"
        bet-type="express"
        class="freebets-select"
      />
      <ExpressSummary
        :amount="freebet ? freebet.amount : amount"
        :currency-icon="currencyIcon"
        :outcomes="outcomes"
      />
      <StTransitionExpand>
        <p v-if="betError" class="bet-error">
          {{ betError }}
        </p>
      </StTransitionExpand>
      <StTransitionExpand>
        <MNotEnoughtError
          v-if="isNegativeBallanceError && mobile"
          class="balance-error"
          @on-close="handleCloseNegativeError"
        />
      </StTransitionExpand>
      <StTransitionExpand>
        <NotEnoughtError
          v-if="isNegativeBallanceError && !mobile"
          @on-close="closeNegativeBallanceError"
        />
      </StTransitionExpand>
      <StButton
        submit
        :label="buttonLabel"
        :loading="isLoading"
        class="submit-button"
        data-t="form-submit-button-express-zkf1"
      />
    </div>
  </form>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
import { useAccountsStore } from '@st/payments/stores/useAccountsStore'
import { useUserStore } from '@st/user/stores/useUserStore'
import type { Freebet } from '@st/bonuses/types'
import { truncRate } from '@st/utils'
import { useFreebetsStore } from '@st/bonuses/stores/useFreebetsStore'
import { useCouponStore } from '../../stores/useCouponStore'
import ExpressOutcomesList from './parts/ExpressOutcomesList.vue'
import ExpressSummary from './parts/ExpressSummary.vue'
import { useLimits } from '../../composables/useLimits'
import { useLocalizeBetError } from '../../composables/useLocalizeBetError'
import { useValidateBetAmount } from '../../composables/useValidateBetAmount'
import FreebetsSelect from '../FreebetsSelect/FreebetsSelect.vue'
import type { CreateBetResult } from '../../composables/useCreateBet'
import { useNotEnoughtError } from '../../composables/useNotEnoughtError'
import NotEnoughtError from '../NotEnoughtError/NotEnoughtError.vue'
import MNotEnoughtError from '../NotEnoughtError/MNotEnoughtError.vue'

defineProps<{
  mobile?: boolean
}>()

const { isAuthenticated } = storeToRefs(useUserStore())
const { activeAccount } = storeToRefs(useAccountsStore())
const { notUsedFreebets } = storeToRefs(useFreebetsStore())
const { t } = useI18n()
const toast = useToast()

const couponStore = useCouponStore()
const { outcomes, isLoading } = storeToRefs(couponStore)

const amount = ref<string>('')
const freebet = ref<Freebet>()

const buttonLabel = ref(t('coupon.makeBet'))

const { expressLimit } = useLimits({
  outcomes,
  currencyId: computed(() => activeAccount.value?.currencyId ?? 1),
})

const maxAmount = computed(() =>
  Decimal.min(
    expressLimit.value.max,
    activeAccount.value?.balance ?? 0,
  ).toString(),
)

const currencyIcon = computed(() => {
  if (!isAuthenticated.value) return 'c-crypto-icon-usdt'

  if (freebet.value) return freebet.value.currencyIcon

  return activeAccount.value?.icon
})

const amountError = ref('')

watch(amount, () => {
  amountError.value = ''
})

const betError = ref('')
const { isNegativeBallanceError, betErrorType, closeNegativeBallanceError } =
  useNotEnoughtError()

const localizeBetError = useLocalizeBetError()

const validateBetAmount = useValidateBetAmount()
const router = useRouter()

function validate(): boolean {
  if (!isAuthenticated.value) {
    router.replace({ query: { modal: 'login' } })
    return false
  }

  if (outcomes.value.some((outcome) => !outcome.isActive)) {
    betError.value = t('coupon.errors.marketNotActive')
    return false
  }

  if (!freebet.value) {
    const { isValid, error, errorType } = validateBetAmount({
      amount: amount.value,
      balance: activeAccount.value.balance,
      limit: expressLimit.value,
    })

    if (!isValid) {
      amountError.value = error ?? ''
      betErrorType.value = errorType
      return false
    }
  }

  return true
}

function handleBetResult(result: CreateBetResult) {
  if (result.isAccepted) {
    toast.open({
      label: t('coupon.betIsAccepted'),
      type: 'positive',
    })

    couponStore.clear()
  } else {
    toast.open({
      label: t('coupon.betIsNotAccepted'),
      type: 'negative',
    })

    betError.value = localizeBetError(result.error)
  }
}

const { createExpressBet } = useCreateExpressBet({
  outcomes,
  amount,
  freebet,
  accountId: computed(() => activeAccount.value?.id),
})

async function handleSubmit() {
  const isValid = validate()
  if (!isValid) return

  isLoading.value = true
  const result = await createExpressBet()
  isLoading.value = false

  handleBetResult(result)
}

const totalRate = computed(() => {
  const decimalRate = outcomes.value.reduce(
    (acc, outcome) => acc.times(outcome.outcome.odds),
    new Decimal(1),
  )

  return truncRate(decimalRate.toNumber())
})

const emits = defineEmits<{
  (e: 'close'): void
}>()
async function handleCloseNegativeError() {
  closeNegativeBallanceError()
  emits('close')
}
</script>

<style scoped>
.outcomes-wrapper {
  flex-shrink: 1;
  min-height: 0;
  padding-inline: var(--spacing-150);
}

.express-bottom {
  display: flex;
  flex-direction: column;
  padding: var(--spacing-150);
}

.bet-error {
  margin: 0 0 var(--spacing-100);
  padding: 0 var(--spacing-100);
  font: var(--desktop-text-xs-medium);
  color: var(--system-error);
}

.submit-button.loading {
  z-index: 1;
  box-shadow: 0 0 0 100vmax rgba(0 0 0 / 60%);
}

.express-form {
  display: flex;
  flex-direction: column;

  &.mobile {
    .outcomes-wrapper {
      padding-inline: var(--spacing-200);
    }

    .express-bottom {
      padding: var(--spacing-150) var(--spacing-200);
    }
  }
}

.freebets-select {
  margin: var(--spacing-075) var(--spacing-100) 0;
}

.balance-error {
  margin-bottom: var(--spacing-150);
}
</style>
