import type { CreateBetError } from './useCreateBet'

export function useLocalizeBetError() {
  const { t } = useI18n()

  const errorMap: Partial<Record<CreateBetError, string>> = {
    BET_RATE_CHANGED: t('coupon.errors.betRateChanged'),
    MARKET_NOT_ACTIVE: t('coupon.errors.marketNotActive'),
  }

  const defaultError = t('coupon.errors.somethingWentWrong')

  return (error: CreateBetError) => errorMap[error] ?? defaultError
}
