import type { Ref } from 'vue'
import type { Freebet } from '@st/bonuses/types'
import type { IconName } from '@st/ui/components/StIcon/types'
import type { ExtendedCouponOutcome } from '../stores/useCouponStore'
import { useCreateBet, type CreateBetResult } from './useCreateBet'

type MaybeRef<T> = T | Ref<T>

export interface Ordinary {
  outcome: ExtendedCouponOutcome
  amount: string
  freebet?: Freebet
  currencyId: number
  currencyIcon: IconName
}

export interface CreateOrdinaryBetParams {
  ordinaries: MaybeRef<Ordinary[]>
  accountId: MaybeRef<number>
}

export type CreateOrdinaryBetReturn = Promise<Record<string, CreateBetResult>>

export function useCreateOrdinaryBet() {
  const { createBet } = useCreateBet()

  async function createOrdinaryBet(
    params: CreateOrdinaryBetParams,
  ): CreateOrdinaryBetReturn {
    const ordinaries = unref(params.ordinaries)

    const promises = ordinaries.map(({ outcome, amount, freebet }) =>
      freebet
        ? createBet({
            betType: 'ordinary',
            outcomes: [outcome],
            freebet,
          })
        : createBet({
            betType: 'ordinary',
            outcomes: [outcome],
            amount,
            accountId: unref(params.accountId),
          }),
    )

    const betResults = await Promise.all(promises)

    const betResultsByOutcome = betResults.reduce<
      Record<string, CreateBetResult>
    >((acc, result, index) => {
      const outcomeUniqId = ordinaries[index].outcome.uniqId
      acc[outcomeUniqId] = result
      return acc
    }, {})

    return betResultsByOutcome
  }

  return { createOrdinaryBet }
}
