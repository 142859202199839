<template>
  <div class="freebets-select" data-t="freebets-select">
    <span class="label">{{ t('coupon.freebet.select') }}</span>
    <div class="items">
      <FreebetSelectItem
        v-for="freebet in notUsedFreebets"
        :key="freebet.id"
        :freebet="freebet"
        :selected="freebet.id === selectedFreebet?.id"
        :bet-rate="betRate"
        :bet-type="betType"
        :already-selected-freebets="alreadySelectedFreebets"
        @select="handleFreebetClick(freebet)"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useFreebetsStore } from '@st/bonuses/stores/useFreebetsStore'
import type { Freebet } from '@st/bonuses/types'
import FreebetSelectItem from './FreebetSelectItem.vue'

const props = defineProps<{
  modelValue?: Freebet
  betRate: string
  betType: 'express' | 'ordinary'
  alreadySelectedFreebets?: Freebet[]
}>()

const emit = defineEmits<{
  (e: 'update:modelValue', value?: Freebet): void
}>()

const { t } = useI18n()

const selectedFreebet = computed({
  get: () => props.modelValue,
  set: (value?: Freebet) => emit('update:modelValue', value),
})

const freebetsStore = useFreebetsStore()
const { notUsedFreebets } = storeToRefs(freebetsStore)

function handleFreebetClick(freebet: Freebet) {
  if (selectedFreebet.value?.id === freebet.id) {
    selectedFreebet.value = undefined
  } else {
    selectedFreebet.value = freebet
  }
}
</script>

<style scoped>
.items {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-075);
  margin-top: var(--spacing-075);
}

.label {
  display: block;
  font: var(--desktop-text-xs-medium);
}
</style>
